define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/discourse-post-event-info/info", ["exports", "truth-helpers", "discourse/helpers/body-class", "discourse/plugins/discourse-local-guides-connect/discourse/helpers/reveal-meetup-url", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _truthHelpers, _bodyClass, _revealMeetupUrl, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Info = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @outletArgs.event.watchingInvitee.status}}
      {{bodyClass "event-going"}}
    {{/if}}
  
    {{#if
      (and
        (revealMeetupUrl @outletArgs.event.startsAt)
        (eq @outletArgs.event.watchingInvitee.status "going")
      )
    }}
      <@outletArgs.Url />
      {{bodyClass "meetup-url-visible"}}
    {{/if}}
  
    {{#if @outletArgs.event.customFields.location}}
      <@outletArgs.Section @icon="location-pin" class="event-location">
        {{@outletArgs.event.customFields.location}}
      </@outletArgs.Section>
    {{/if}}
  
    <@outletArgs.Dates />
    <@outletArgs.Invitees />
    <@outletArgs.Status />
  
  */
  {
    "id": "ivqyZJWq",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"event\",\"watchingInvitee\",\"status\"]],[[[1,\"    \"],[1,[28,[32,0],[\"event-going\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[32,1],[[28,[32,2],[[30,1,[\"event\",\"startsAt\"]]],null],[28,[32,3],[[30,1,[\"event\",\"watchingInvitee\",\"status\"]],\"going\"],null]],null],[[[1,\"    \"],[8,[30,1,[\"Url\"]],null,null,null],[1,\"\\n    \"],[1,[28,[32,0],[\"meetup-url-visible\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"event\",\"customFields\",\"location\"]],[[[1,\"    \"],[8,[30,1,[\"Section\"]],[[24,0,\"event-location\"]],[[\"@icon\"],[\"location-pin\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,1,[\"event\",\"customFields\",\"location\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[30,1,[\"Dates\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"Invitees\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"Status\"]],null,null,null],[1,\"\\n\"]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/discourse-post-event-info/info.js",
    "scope": () => [_bodyClass.default, _truthHelpers.and, _revealMeetupUrl.default, _truthHelpers.eq],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "info:Info"));
  var _default = _exports.default = Info;
});