define("discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/home-logo__before/logo", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href="/" class="logo-full">
    <img
      src="/plugins/discourse-local-guides-connect/images/logo.svg"
      class="logomark"
    />
  
    <h1 class="text-logo">
      Local Guides Connect
    </h1>
  </a>
  */
  {
    "id": "HrZ9u/Lp",
    "block": "[[[10,3],[14,6,\"/\"],[14,0,\"logo-full\"],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/plugins/discourse-local-guides-connect/images/logo.svg\"],[14,0,\"logomark\"],[12],[13],[1,\"\\n\\n  \"],[10,\"h1\"],[14,0,\"text-logo\"],[12],[1,\"\\n    Local Guides Connect\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"a\",\"img\",\"h1\"]]",
    "moduleName": "discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/home-logo__before/logo.hbs",
    "isStrictMode": false
  });
});