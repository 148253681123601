define("discourse/plugins/discourse-local-guides-connect/discourse/components/edit-meetup-btn", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/composer", "discourse-i18n", "discourse/plugins/discourse-local-guides-connect/discourse/lib/constants", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _ajax, _ajaxError, _composer3, _discourseI18n, _constants, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EditMeetupBtn extends _component.default {
    static shouldRender(args, context) {
      return !args.post.canEdit && args.post.topic.category_id === parseInt(context.siteSettings.meetup_category, 10) && args.post.user_id === context.currentUser?.id && !args.post.topic.tags.includes(context.siteSettings.meetup_recap_tag);
    }
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    editMeetup() {
      this.dialog.confirm({
        title: (0, _discourseI18n.i18n)("meetup_form.edit_submission_note.title"),
        message: (0, _discourseI18n.i18n)("meetup_form.edit_submission_note.message"),
        confirmButtonLabel: "meetup_form.edit_submission_note.confirm",
        didConfirm: async () => {
          const post = this.args.post;
          try {
            const response = await (0, _ajax.ajax)(`/posts/${post.id}/raw`, {
              dataType: "text"
            });
            this.composer.open({
              action: _composer3.default.PRIVATE_MESSAGE,
              recipients: this.siteSettings.meetup_submission_group,
              topicTitle: post.event.name,
              topicBody: response,
              archetypeId: "private_message",
              tags: post.topic.tags.filter(tag => tag.length !== 2),
              draftKey: _composer3.default.NEW_PRIVATE_MESSAGE_KEY,
              topic: {
                [_constants.EVENT_TOPIC_ID_FIELD]: post.topic.id,
                [_constants.EVENT_TYPE_FIELD]: post.topic[_constants.EVENT_TYPE_FIELD],
                [_constants.LOCATION_FIELD]: post.topic[_constants.LOCATION_FIELD]
              }
            });
          } catch (e) {
            (0, _ajaxError.popupAjaxError)(e);
          }
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "editMeetup", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="edit-meetup"
          ...attributes
          @action={{this.editMeetup}}
          @icon="pencil-alt"
          @label="meetup_form.edit_meetup"
          @title="meetup_form.edit_meetup"
        />
      
    */
    {
      "id": "z0peIncX",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"edit-meetup\"],[17,1]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"editMeetup\"]],\"pencil-alt\",\"meetup_form.edit_meetup\",\"meetup_form.edit_meetup\"]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/edit-meetup-btn.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EditMeetupBtn;
});