define("discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/topic-category/title-location", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.topic.local_guides_location.country}}
    <a
      class="topic-location"
      href="/tag/{{this.topic.local_guides_location.country}}"
    >
      {{i18n
        (concat
          "local_guides_connect.countries."
          this.topic.local_guides_location.country
        )
      }}
    </a>
  {{/if}}
  */
  {
    "id": "EotaFnjQ",
    "block": "[[[41,[30,0,[\"topic\",\"local_guides_location\",\"country\"]],[[[1,\"  \"],[10,3],[14,0,\"topic-location\"],[15,6,[29,[\"/tag/\",[30,0,[\"topic\",\"local_guides_location\",\"country\"]]]]],[12],[1,\"\\n    \"],[1,[28,[35,2],[[28,[37,3],[\"local_guides_connect.countries.\",[30,0,[\"topic\",\"local_guides_location\",\"country\"]]],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"a\",\"i18n\",\"concat\"]]",
    "moduleName": "discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/topic-category/title-location.hbs",
    "isStrictMode": false
  });
});