define("discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/latest", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "truth-helpers", "discourse/components/conditional-loading-spinner", "discourse/helpers/concat-class", "discourse/lib/ajax", "discourse/lib/preload-store", "discourse/models/topic-list", "discourse-common/helpers/i18n", "discourse/plugins/discourse-local-guides-connect/discourse/components/topic-formatter", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _tracking, _helper, _modifier, _object, _didInsert, _service, _truthHelpers, _conditionalLoadingSpinner, _concatClass, _ajax, _preloadStore, _topicList, _i18n, _topicFormatter, _component2, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ListTitleButton extends _component.default {
    get title() {
      return `homepage.${this.args.kind}.title`;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <h3 class="g-latest__tab">
          <button
            {{on "click" (fn @onClick @kind)}}
            class={{concatClass
              "g-latest__tab-button"
              "btn"
              "btn-flat"
              (if (eq @currentList @kind) "active")
            }}
            type="button"
          >
            {{i18n this.title}}
          </button>
        </h3>
      
    */
    {
      "id": "vPmbbeKM",
      "block": "[[[1,\"\\n    \"],[10,\"h3\"],[14,0,\"g-latest__tab\"],[12],[1,\"\\n      \"],[11,\"button\"],[16,0,[28,[32,0],[\"g-latest__tab-button\",\"btn\",\"btn-flat\",[52,[28,[32,1],[[30,1],[30,2]],null],\"active\"]],null]],[24,4,\"button\"],[4,[32,2],[\"click\",[28,[32,3],[[30,3],[30,2]],null]],null],[12],[1,\"\\n        \"],[1,[28,[32,4],[[30,0,[\"title\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@currentList\",\"@kind\",\"@onClick\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/latest.js",
      "scope": () => [_concatClass.default, _truthHelpers.eq, _modifier.on, _helper.fn, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  const TopicListSection = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class={{concatClass "g-latest__list" (if @isLoading "loading")}}>
      <ConditionalLoadingSpinner @condition={{@isLoading}} />
      {{#each @topics as |topic|}}
        <TopicFormatter @topic={{topic}} @showThumbnail={{true}} />
      {{/each}}
    </div>
  
    <div class="g-latest__footer">
      <a href={{@link}} role="button" class="g-button--secondary">
        <span>{{i18n "js.read_more"}}</span>
      </a>
    </div>
  
  */
  {
    "id": "nfmuyy3w",
    "block": "[[[1,\"\\n  \"],[10,0],[15,0,[28,[32,0],[\"g-latest__list\",[52,[30,1],\"loading\"]],null]],[12],[1,\"\\n    \"],[8,[32,1],null,[[\"@condition\"],[[30,1]]],null],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,2]],null]],null],null,[[[1,\"      \"],[8,[32,2],null,[[\"@topic\",\"@showThumbnail\"],[[30,3],true]],null],[1,\"\\n\"]],[3]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"g-latest__footer\"],[12],[1,\"\\n    \"],[10,3],[15,6,[30,4]],[14,\"role\",\"button\"],[14,0,\"g-button--secondary\"],[12],[1,\"\\n      \"],[10,1],[12],[1,[28,[32,3],[\"js.read_more\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@isLoading\",\"@topics\",\"topic\",\"@link\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/latest.js",
    "scope": () => [_concatClass.default, _conditionalLoadingSpinner.default, _topicFormatter.default, _i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "latest:TopicListSection"));
  /**
   * @typedef {"latest" | "trending"} AvailableLists
   */
  class HomepageLatest extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "latestTopics", [_tracking.tracked], function () {
      return [];
    }))();
    #latestTopics = (() => (dt7948.i(this, "latestTopics"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "trendingTopics", [_tracking.tracked], function () {
      return [];
    }))();
    #trendingTopics = (() => (dt7948.i(this, "trendingTopics"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "isLoadingTrendingTopics", [_tracking.tracked], function () {
      return true;
    }))();
    #isLoadingTrendingTopics = (() => (dt7948.i(this, "isLoadingTrendingTopics"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "currentList", [_tracking.tracked], function () {
      return "latest";
    }))();
    #currentList = (() => (dt7948.i(this, "currentList"), void 0))();
    /**
    * @type {AvailableLists}
    */
    async getLatestTopics() {
      const topics = _preloadStore.default.get("latest_homepage_topics") ? await _preloadStore.default.getAndRemove("latest_homepage_topics") : await (0, _ajax.ajax)("/homepage-latest.json");
      this.latestTopics = _topicList.default.topicsFrom(this.store, topics);
    }
    static #_7 = (() => dt7948.n(this.prototype, "getLatestTopics", [_object.action]))();
    async getTrendingTopics() {
      try {
        const topics = await (0, _ajax.ajax)("/hot.json");
        this.trendingTopics = _topicList.default.topicsFrom(this.store, topics);
      } finally {
        this.isLoadingTrendingTopics = false;
      }
    }
    /**
    * @param {AvailableLists} list
    */
    static #_8 = (() => dt7948.n(this.prototype, "getTrendingTopics", [_object.action]))();
    switchTo(list) {
      this.fetchCurrentList(list);
      this.currentList = list;
    }
    static #_9 = (() => dt7948.n(this.prototype, "switchTo", [_object.action]))();
    fetchCurrentList(list) {
      // refresh list if it's not the current one
      if (list === "latest" && this.currentList !== "latest") {
        this.getLatestTopics();
      } else if (list === "trending" && this.currentList !== "trending") {
        this.getTrendingTopics();
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "fetchCurrentList", [_object.action]))();
    static #_11 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="g-latest" {{didInsert this.getLatestTopics}}>
          <div class="g-latest__header">
            <ListTitleButton
              @onClick={{this.switchTo}}
              @kind="latest"
              @currentList={{this.currentList}}
            />
            <ListTitleButton
              @onClick={{this.switchTo}}
              @kind="trending"
              @currentList={{this.currentList}}
            />
          </div>
    
          {{#if (eq this.currentList "latest")}}
            <TopicListSection @topics={{this.latestTopics}} @link="/latest" />
          {{else if (eq this.currentList "trending")}}
            <TopicListSection
              @isLoading={{this.isLoadingTrendingTopics}}
              @topics={{this.trendingTopics}}
              @link="/latest?q=order:hot"
            />
          {{/if}}
        </section>
      
    */
    {
      "id": "KKAoglbT",
      "block": "[[[1,\"\\n    \"],[11,\"section\"],[24,0,\"g-latest\"],[4,[32,0],[[30,0,[\"getLatestTopics\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"g-latest__header\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@onClick\",\"@kind\",\"@currentList\"],[[30,0,[\"switchTo\"]],\"latest\",[30,0,[\"currentList\"]]]],null],[1,\"\\n        \"],[8,[32,1],null,[[\"@onClick\",\"@kind\",\"@currentList\"],[[30,0,[\"switchTo\"]],\"trending\",[30,0,[\"currentList\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[28,[32,2],[[30,0,[\"currentList\"]],\"latest\"],null],[[[1,\"        \"],[8,[32,3],null,[[\"@topics\",\"@link\"],[[30,0,[\"latestTopics\"]],\"/latest\"]],null],[1,\"\\n\"]],[]],[[[41,[28,[32,2],[[30,0,[\"currentList\"]],\"trending\"],null],[[[1,\"        \"],[8,[32,3],null,[[\"@isLoading\",\"@topics\",\"@link\"],[[30,0,[\"isLoadingTrendingTopics\"]],[30,0,[\"trendingTopics\"]],\"/latest?q=order:hot\"]],null],[1,\"\\n      \"]],[]],null]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/latest.js",
      "scope": () => [_didInsert.default, ListTitleButton, _truthHelpers.eq, TopicListSection],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = HomepageLatest;
});