define("discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/before-user-profile-avatar/edit-bio", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (eq this.outletArgs.model.username this.currentUser.username)}}
    {{#if (or this.outletArgs.model.bio_raw this.outletArgs.model.bio_cooked)}}
      <LinkTo
        @route="preferences.profile"
        @model={{this.currentUser}}
        role="button"
        class="g-button--secondary profile__edit-bio-button"
      >
        <span></span>
      </LinkTo>
    {{else}}
      <LinkTo
        @route="preferences.profile"
        @model={{this.currentUser}}
        role="button"
        class="g-button--secondary profile__add-bio-button"
      >
        <span>{{i18n "js.user.bio"}}</span>
      </LinkTo>
    {{/if}}
  {{/if}}
  */
  {
    "id": "cTU6rcjH",
    "block": "[[[41,[28,[37,1],[[30,0,[\"outletArgs\",\"model\",\"username\"]],[30,0,[\"currentUser\",\"username\"]]],null],[[[41,[28,[37,2],[[30,0,[\"outletArgs\",\"model\",\"bio_raw\"]],[30,0,[\"outletArgs\",\"model\",\"bio_cooked\"]]],null],[[[1,\"    \"],[8,[39,3],[[24,\"role\",\"button\"],[24,0,\"g-button--secondary profile__edit-bio-button\"]],[[\"@route\",\"@model\"],[\"preferences.profile\",[30,0,[\"currentUser\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],[[24,\"role\",\"button\"],[24,0,\"g-button--secondary profile__add-bio-button\"]],[[\"@route\",\"@model\"],[\"preferences.profile\",[30,0,[\"currentUser\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[12],[1,[28,[35,5],[\"js.user.bio\"],null]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"if\",\"eq\",\"or\",\"link-to\",\"span\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/before-user-profile-avatar/edit-bio.hbs",
    "isStrictMode": false
  });
});