define("discourse/plugins/discourse-local-guides-connect/discourse/api-initializers/localize-categories", ["exports", "discourse/lib/api", "I18n"], function (_exports, _api, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getCategoryDisplayValue(value, context, type) {
    return _I18n.default.t(`categories.${context.category?.slug?.replace(/-/g, "_")}.${type}`, {
      defaultValue: value
    });
  }
  var _default = _exports.default = (0, _api.apiInitializer)("1.34.0", api => {
    api.registerValueTransformer("category-display-name", _ref => {
      let {
        value,
        context
      } = _ref;
      return getCategoryDisplayValue(value, context, "title");
    });
    api.registerValueTransformer("category-description-text", _ref2 => {
      let {
        value,
        context
      } = _ref2;
      return getCategoryDisplayValue(value, context, "description");
    });
  });
});