define("discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/user-profile-controls/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (eq this.outletArgs.model.username this.currentUser.username)}}
    <LinkTo
      @route="preferences"
      @model={{this.currentUser}}
      role="button"
      class="g-button--secondary profile__edit-button"
    >
      <span>{{i18n "js.edit"}}</span>
    </LinkTo>
  {{/if}}
  */
  {
    "id": "GuqPfrCn",
    "block": "[[[41,[28,[37,1],[[30,0,[\"outletArgs\",\"model\",\"username\"]],[30,0,[\"currentUser\",\"username\"]]],null],[[[1,\"  \"],[8,[39,2],[[24,\"role\",\"button\"],[24,0,\"g-button--secondary profile__edit-button\"]],[[\"@route\",\"@model\"],[\"preferences\",[30,0,[\"currentUser\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[12],[1,[28,[35,4],[\"js.edit\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"eq\",\"link-to\",\"span\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/user-profile-controls/edit.hbs",
    "isStrictMode": false
  });
});